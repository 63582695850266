<template>
    <v-app>
        <v-app-bar dark absolute color="indigo darken-4">

            <h3>AUDIDOC</h3>
            
        </v-app-bar>
        <v-main>
             <v-container fluid class="fill-height">
                    <v-row  align="center" justify="center">
                        <v-progress-linear
                            indeterminate
                            class="mx-auto text-center"
                            color="blue darken-4"
                            v-show="upload"
                        />
                        <v-card class="mx-auto" max-width="800">
                            <v-toolbar dark color="indigo darken-4">
                            <v-toolbar-title>
                                Caso: {{parametrosRuta.data.nombre_documento}}
                            </v-toolbar-title>
                        </v-toolbar>
                            <v-card-text>
                                <h3 style="text-align:center"> Adjunta evidencias de la "accion" que debes realizar </h3>
                                <Drop
                                            v-on:info="archivos_seleccionados"
                                            :permitir="'allFields'"
                                />
                            </v-card-text>
                        </v-card>
                    </v-row>
             </v-container>       
            
        </v-main>
        <v-dialog v-model="load" fullscreen >
            <v-card>
                <v-container fluid class="fill-height">
                    <v-row  align="center" justify="center">
                        
                            <v-card-text>
                                <h3 style="text-align:center"> {{buscando==true ? 'Estamos verificando su identidad...' : 'No existe informacion de este caso' }} </h3>
                        
                            </v-card-text>
                            <v-card-text v-if="buscando">
                                <p style="text-align:center">Por favor espere un momento </p>
                            </v-card-text>
                            <v-card-actions v-if="buscando">
                                <v-progress-circular
                                class="mx-auto"
                                :size="70"
                                :width="7"
                                color="indigo darken-4"
                                indeterminate
                                ></v-progress-circular>
                            </v-card-actions>
                            <v-card-actions v-else >
                                <v-row>
                                    <v-col>
                                        <v-row>
                                            <v-img contain width=300 :src="require('../assets/notFound.png')">

                                            </v-img>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <p> Wops, no tenemos informacion asociada a su caso. Si es una equivocacion informe a la empresa </p>
                                            </v-col>
                                             
                                        </v-row>
                                    </v-col>
                                     
                                  
                                </v-row>
                                
                                  
                                
                            </v-card-actions>
                    </v-row>
                </v-container>  
            </v-card>   
        </v-dialog>
    </v-app>
</template>
<script>
import Drop from '../components/Drop'
const axios = require("axios").default;
import acciones_casos_supervisor from '../mixins/acciones_casos_supervisor'

export default {
    components: {
        Drop
    },
    mixins: [acciones_casos_supervisor],
    data(){
        return {
            load: true,
            parametrosRuta: {
                data: {
                    nombre_documento: ''
                }
            },
            buscando: true,
            datos_adjuntos: [],
            upload: false,
        }
    },
    beforeCreate(){
        console.log(this.$route.params)
        if(this.$route.params.id==""){
            this.$router.replace('/')
        }
        
    },
   async created(){
        this.$route.params.action = 'No'
        console.log(Date.parse(new Date()))
        
       
        console.log(this.$route.params)
       try {
        let aux = this.$route.params.id.split('|') //Object id coleccion y nodo del workflow
        console.log(aux)

        let response = await this.$store.dispatch('llamado_get',
        {url: 'https://audidoc.educandote.co/parse/classes/prueba01casos/'+aux[0],tipo_header:'parse'})
        console.log(response.data)
        if(response.data.estado=='Pendiente'){
            this.load = false
            this.parametrosRuta = {
                data: response.data
            } 
        }else{
            this.buscando = false
        }
        
        
       } catch (error) {
         
           console.log(error.message)
           if(error.message=='Error: Request failed with status code 404'){
              this.buscando = false
           }else{
               this.$store.commit('error','Wops, Estamos teniendo problemas con nuestros servidores... por favor intenta mas tarde ')
           }
           
          
       
       }
        
    },
    methods: {
      async  archivos_seleccionados(archivos){
            
            console.log(archivos);
            let token =  'usuario_'+Date.parse(new Date())
            try {
                for (let index = 0; index < archivos.obj.length; index++) {
                    let file = new FormData();
                    file.append("file", archivos.archivo[index]);
                    file.append("texto", token);
                    file.append("nombre", archivos.obj[index].nombre);

                    let obj = {
                        nombre: archivos.obj[index].nombre,
                        token: token,
                        tipo: archivos.obj[index].tipo,
                        autor: this.parametrosRuta.data.correo_de_contacto,
                        sector: 'Notificar usuario',
                    };

                    obj = JSON.stringify(obj);
                    this.$store.commit("auth");

                    if (index + 1 == 1) {
                        //Upload docu crea la carpeta y anexa la primera imagen
                        let responseUploadImg = await axios.post(
                        "https://drive.educandote.co/audidoc/uploadDocu.php",
                        file,
                        {
                            header: {
                            "Content-Type": "multipart/form-data",
                            Authorization: this.$store.state.header,
                            },
                        }
                        );
                        console.log("Upload Imagen 1", responseUploadImg);
                    } else {
                        //upgradeFolder.php anexa, datos al folder asociado al token

                        let response = await axios.post(
                        "https://drive.educandote.co/audidoc/upgradeFolder.php",
                        file,
                        {
                            header: {
                            "Content-Type": "multipart/form-data",
                            Authorization: this.$store.state.header,
                            },
                        }
                        );

                        console.log("Upload Imagen " + index);
                        console.log(response);
                    }
                    let response = await this.$store.dispatch('llamado_put',
                    {url: ' https://audidoc.educandote.co/parse/classes/prueba01casos/'+this.parametrosRuta.data.objectId,
                        body: {
                            informacion_area: {
                                __op: "AddUnique",
                                objects: [obj],
                            },
                        },
                        tipo_header: 'parse'
                    })
                    console.log(response)
                    
                }
                this.seguir_flujo()
            } catch (error) {
                this.$store.commit('error','Algo ha salido subiendo a la nube sus archivos. Por favor intente mas tarde')
                this.upload = false
                console.log(error)
            }
            
            
        },
        async seguir_flujo(){
            try {
                this.parametrosRuta.data.estado = 'Aceptado'
           
                let response = await this.$store.dispatch('llamado_get',
                {url: 'https://audidoc.educandote.co/parse/classes/prueba01casos/'+this.parametrosRuta.data.objectId,tipo_header:'parse'})
                console.log(response)
                this.$store.state.dataUser.dataUser.nombre = this.parametrosRuta.data.correo_de_contacto
                this.$store.state.dataUser.dataUser.identificacion = this.parametrosRuta.data.correo_de_contacto
                this.$store.state.dataUser.dataUser.correo = this.parametrosRuta.data.correo_de_contacto
                this.$store.state.dataUser.dataUser.sector = 'Notificar al usuario'
                //this.parametrosRuta.data.formularios = [...response.data.formularios]
                this.parametrosRuta.data.carpeta_publica = [...response.data.carpeta_publica]
                this.$store.state.archivos_seleccionados = [...response.data.informacion_area]
                console.log('?')
                this.datos_adjuntos = [...response.data.carpeta_publica,...response.data.informacion_area]
               let caso = await this.responder_caso()
               if(caso){
                   this.$store.state.dialogCargando = false
                   this.$store.commit('exitoso','El proceso ha sido culminado exitosamente')
                   setTimeout(()=> { this.$router.push('/') }, 3000);
               }
               
                
            } catch (error) {
                this.$store.state.dialogCargando = false
                this.$store.commit('error','Algo ha salido mal subiendo sus documentos para proseguir con el caso. Por favor intente mas tarde')
                console.log(error)
            }
            
        },
        async responder_caso(){
            /*
             var f = new Date();
             var fecha = f.getFullYear() + "-" + (("0" + (f.getMonth() + 1)).slice(-2)) + "-" + ("0" + f.getDate()).slice(-2)
            */  
            console.log(this.parametrosRuta.data)
              console.log(this.$route.params.action)
              let res
              if (this.parametrosRuta.data.estado == 'Respondido') { //En caso de la reasignacion
               res =  await this.enviar_informacion_al_siguiente_sector('Respondido', this.comentario)
              } else { //En caso de una revision normal 
                

                  //En esta parte del rechazar caso 

                  let body = {
                      "nombre_supervisor": this.$store.state.dataUser.dataUser.nombre,
                      "identificacion_supervisor": this.$store.state.dataUser.dataUser.identificacion,
                      "correo_supervisor": this.$store.state.dataUser.dataUser.correo,
                      "estado": 'Respondido',
                      "fecha_respondido": Date.parse(new Date()),
                  }
                res =  await  this.enviar_estado_respuesta(body)
              }
              return res
        }
    }
}
</script>